import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueRouter from 'vue-router';
import axios from 'axios';
Object.defineProperty(Vue.prototype, '$axios', { value: axios });
import router from './router';
import VueGtag from "vue-gtag";
const _ = require('lodash');

//  cookie_flags: 'SameSite=None;Secure',
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG },
  appName: 'Unipegaso',
  pageTrackerScreenviewEnabled: true
}, router);
Vue.use(require('vue-cookies'));

Vue.config.productionTip = false;


new Vue({
  router,
  render: h => h(App),
  data: ()=> {
    return {
       publicPdsOptionsList: [],
       publicCdlOptionsList: [],
       publicEnrollsOptionsList: [],
       publicResidenceCitiesList: [],
       publicResidenceProvincesList: [],
       publicResidenceCountriesList: [],
       publicBirthCitiesList: [],
       publicBirthProvincesList: [],
       publicBirthCountriesList: [],
       publicConventionsList: [],
       publicInstallmentsList: [],
       publicFormativeProgramsList: [],
       publicProgramsList: [],
       publicAcademicYearOptionsList: [],
       publicCurrentYear: "",
       publicUniApiCallProspectus: '',
       publicUniDefaultConvention: {defaultConventionId: ''},
       publicDoppiaLaureaOptionsList: [ {value: 'U', text: 'Iscrizione Unica'}, {value:'Q', text: 'Già iscritto presso questo Ateneo'},{ value:'A',text:'Già iscritto presso altro Ateneo'}],
       publicExternalUniversities: [],
       domain: location.host.substring(location.host.indexOf('.')+1)
    }
  },
  methods: {

    getPublicPdsOptionsList: function(uniId) {
      this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/5'), {
        params: {
          uni: uniId
          }
      })
        .then((response) => {
          this.publicPdsOptionsList = [];
          this.publicCdlOptionsList = [];
          for (let indexCdl = 0; indexCdl < response.data.length; indexCdl++) {
            this.$set(this.publicCdlOptionsList, indexCdl, { value: response.data[indexCdl].id, text: response.data[indexCdl].name, code: response.data[indexCdl].code } );
            let indexOffset = this.publicPdsOptionsList.length;
            for ( let indexPds = 0; indexPds < response.data[indexCdl].Pds.length; indexPds++) {
              this.$set(this.publicPdsOptionsList, indexPds + indexOffset, { value: response.data[indexCdl].Pds[indexPds].id, text: response.data[indexCdl].Pds[indexPds].name, cdl_id: response.data[indexCdl].Pds[indexPds].cdl_id } );
            }
          }
        })
        .catch( () => {
            this.$root.$emit('connection-aborted');
    /*      let options = {
            toastTitle: 'Error',
            toastContent: error.message,
            autoHideDelay: 10000
          };
          this.makeToast(options);*/
        })
    },

    getPublicEnrollsOptionsList: function() {
      this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/4'), {
      })
        .then((response) => {
          this.publicEnrollsOptionsList = [];
          for (let index = 0; index < response.data.length; index++) {
            this.$set(this.publicEnrollsOptionsList, index, { value: response.data[index].id, text: response.data[index].type  } );
          }
        })
        .catch( () => {
          this.$root.$emit('connection-aborted');
          /*console.log(error.toJSON())
          let options = {
            toastTitle: 'Error',
            toastContent: error,
            autoHideDelay: 10000
          };
          this.makeToast(options);*/
        })
    },

    getPublicBirthLocationsList: function() {
      this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/2'), {
      })
        .then((response) => {
          this.publicBirthCitiesList = [];
          this.publicBirthProvincesList = [];
          this.publicBirthCountriesList = [];

          for (let indexCountries = 0; indexCountries < response.data.length; indexCountries++) {
            this.$set(this.publicBirthCountriesList, indexCountries, { value: response.data[indexCountries].id, text: response.data[indexCountries].name  } );

            if ( response.data[indexCountries].provinces.length > 0 ) {
              for (let indexProvinces = 0; indexProvinces < response.data[indexCountries].provinces.length; indexProvinces++) {

                this.$set(this.publicBirthProvincesList, indexProvinces, {  value: response.data[indexCountries].provinces[indexProvinces].id, text: response.data[indexCountries].provinces[indexProvinces].name, country_id: response.data[indexCountries].id  } );

                let indexOffset = this.publicBirthCitiesList.length;
                if ( typeof response.data[indexCountries].provinces[indexProvinces].cities !== 'undefined' && response.data[indexCountries].provinces[indexProvinces].cities.length > 0 ) {
                for (let indexCities = 0; indexCities < response.data[indexCountries].provinces[indexProvinces].cities.length; indexCities++) {

                  this.$set(this.publicBirthCitiesList, indexCities + indexOffset, { value: response.data[indexCountries].provinces[indexProvinces].cities[indexCities].id, text: response.data[indexCountries].provinces[indexProvinces].cities[indexCities].name, province_id:  response.data[indexCountries].provinces[indexProvinces].id  } );

                  }
                }
              }
            }
          }
          /* REORDER */
          this.publicBirthCountriesList = _.orderBy(this.publicBirthCountriesList, ['text'], ['asc']);
          this.publicBirthProvincesList = _.orderBy(this.publicBirthProvincesList, ['text'], ['asc']);

        })
        .catch( () => {
          this.$root.$emit('connection-aborted');
      /*    let options = {
            toastTitle: 'Error',
            toastContent: error,
            autoHideDelay: 10000
          };
          this.makeToast(options);*/
        })
    },

    getPublicResidenceLocationsList: function() {
      this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/3'), {
      })
        .then((response) => {
          this.publicResidenceCitiesList = [];
          this.publicResidenceProvincesList = [];
          this.publicResidenceCountriesList = [];
          for (let indexCountries = 0; indexCountries < response.data.length; indexCountries++) {
            this.$set(this.publicResidenceCountriesList, indexCountries, { value: response.data[indexCountries].id, text: response.data[indexCountries].name  } );

            if ( response.data[indexCountries].provinces.length > 0 ) {
              for (let indexProvinces = 0; indexProvinces < response.data[indexCountries].provinces.length; indexProvinces++) {

                this.$set(this.publicResidenceProvincesList, indexProvinces, {  value: response.data[indexCountries].provinces[indexProvinces].id, text: response.data[indexCountries].provinces[indexProvinces].name, country_id: response.data[indexCountries].id  } );

                let indexOffset = this.publicResidenceCitiesList.length;
                if ( typeof response.data[indexCountries].provinces[indexProvinces].cities !== 'undefined' && response.data[indexCountries].provinces[indexProvinces].cities.length > 0 ) {

                for (let indexCities = 0; indexCities < response.data[indexCountries].provinces[indexProvinces].cities.length; indexCities++) {

                  this.$set(this.publicResidenceCitiesList, indexCities + indexOffset, { value: response.data[indexCountries].provinces[indexProvinces].cities[indexCities].id, text: response.data[indexCountries].provinces[indexProvinces].cities[indexCities].name, province_id:  response.data[indexCountries].provinces[indexProvinces].id  } );

                  }
                }
              }
            }
          }
          /* REORDER */
          this.publicResidenceCountriesList = _.orderBy(this.publicResidenceCountriesList, ['text'], ['asc']);
          this.publicResidenceProvincesList = _.orderBy(this.publicResidenceProvincesList, ['text'], ['asc']);

        })
        .catch( () => {
          this.$root.$emit('connection-aborted');
          /*let options = {
            toastTitle: 'Error',
            toastContent: error,
            autoHideDelay: 10000
          };
          this.makeToast(options);*/
        })
    },
   getPublicConventionsList: function(uniId) {
     this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/6'), {
       params: {
         uni: uniId
         }
     })
       .then((response) => {
         this.publicConventionsList = [];
         for (let indexConventions = 0; indexConventions < response.data.length; indexConventions++) {
           this.$set(this.publicConventionsList, indexConventions, { value: response.data[indexConventions].id, text: response.data[indexConventions].name, id_original: response.data[indexConventions].id_original  } );
         }
       })
       .catch( () => {
         this.$root.$emit('connection-aborted');
         /*let options = {
           toastTitle: 'Error',
           toastContent: error,
           autoHideDelay: 10000
         };
         this.makeToast(options);*/
       })
   },
   getPublicInstallmentsList: function() {
     this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/7'), {
     })
       .then((response) => {
         this.publicInstallmentsList = [];
         for (let indexInstallments = 0; indexInstallments < response.data.length; indexInstallments++) {
           this.$set(this.publicInstallmentsList, indexInstallments, { value: response.data[indexInstallments].num_rate, text: response.data[indexInstallments].num_rate, id_original: response.data[indexInstallments].id_original  } );
         }
       })
       .catch( () => {
         this.$root.$emit('connection-aborted');
         /*let options = {
           toastTitle: 'Error',
           toastContent: error,
           autoHideDelay: 10000
         };
         this.makeToast(options);*/
       })
   },
   getPublicFormativeProgramsList: function(cdlId) {
     this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/8'), {
       params: {
         id: cdlId
         }
     })
       .then((response) => {
         this.publicFormativeProgramsList = [];
         for ( let indexFormProg = 0; indexFormProg < response.data.length; indexFormProg++) {
           this.$set(this.publicFormativeProgramsList, indexFormProg, { value: response.data[indexFormProg].label, text: response.data[indexFormProg].text, formProgId: response.data[indexFormProg].id } );
          }
       })
       .catch( () => {
           this.$root.$emit('connection-aborted');
   /*      let options = {
           toastTitle: 'Error',
           toastContent: error.message,
           autoHideDelay: 10000
         };
         this.makeToast(options);*/
       })
   },
   getPublicProgramsList: function(formativeProgramId) {
     this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/9'), {
       params: {
         id: formativeProgramId
         }
     })
       .then((response) => {
         this.publicProgramsList = [];
         for ( let indexProg = 0; indexProg < response.data.length; indexProg++) {
           this.$set(this.publicProgramsList, indexProg, { value: response.data[indexProg].name, text: response.data[indexProg].name, id_original: response.data[indexProg].id_original } );
          }
       })
       .catch( () => {
           this.$root.$emit('connection-aborted');
   /*      let options = {
           toastTitle: 'Error',
           toastContent: error.message,
           autoHideDelay: 10000
         };
         this.makeToast(options);*/
       })
   },
   getPublicCurrentYear: function() {
     this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/10'), {
     })
       .then((response) => {
          this.$set(this.publicAcademicYearOptionsList, 0, {value: response.data.name, text: response.data.name} );
          this.publicCurrentYear = response.data.name;

       })
       .catch( () => {
           this.$root.$emit('connection-aborted');
   /*      let options = {
           toastTitle: 'Error',
           toastContent: error.message,
           autoHideDelay: 10000
         };
         this.makeToast(options);*/
       })
   },

   getpublicUniApiCallProspectus : function(uniId) {
     this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/11'), {
         params: {
           uni: uniId
           }
       })
       .then((response) => {
          this.publicUniApiCallProspectus = response.data.api_call_url_prospectus
          this.$set(this.publicUniDefaultConvention, 'defaultConventionId', response.data.defaultConventionId);
       })
       .catch( () => {
           this.$root.$emit('connection-aborted');
   /*      let options = {
           toastTitle: 'Error',
           toastContent: error.message,
           autoHideDelay: 10000
         };
         this.makeToast(options);*/
       })
   },


   getPublicExternalUniversities : function() {
     this.$axios.get(process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat('/public/12'), {
         params: {
           }
       })
       .then((response) => {
         this.publicExternalUniversities = [];
         for ( let indexExtUnis = 0; indexExtUnis < response.data.length; indexExtUnis++) {
           this.$set(this.publicExternalUniversities, indexExtUnis, { value: response.data[indexExtUnis].id, text: response.data[indexExtUnis].name} );
          }
       })
       .catch( () => {
           this.$root.$emit('connection-aborted');
   /*      let options = {
           toastTitle: 'Error',
           toastContent: error.message,
           autoHideDelay: 10000
         };
         this.makeToast(options);*/
       })
   },

    makeToast: function(options = {
      toastTitle: 'Title',
      toastContent: 'Info',
      autoHideDelay: 5000,
      appendToast: false
    }) {
      this.$bvToast.toast(options.toastContent, {
        title: options.toastTitle,
        autoHideDelay: options.autoHideDelay,
        appendToast: options.appendToast
      })
    }
  },

  created: function onCreate() {
    this.$root.$on('update-publicpds-list', (uniId) => {
      this.getPublicPdsOptionsList(uniId);
    }),
    this.$root.$on('update-publicenrolls-list', () => {
      this.getPublicEnrollsOptionsList();
    }),
    this.$root.$on('update-birthlocations-list', () => {
      this.getPublicBirthLocationsList();
    }),
    this.$root.$on('update-residencelocations-list', () => {
      this.getPublicResidenceLocationsList();
    }),
    this.$root.$on('update-publicconventions-list', (uniId) => {
      this.getPublicConventionsList(uniId);
    }),
    this.$root.$on('update-publicinstallments-list', () => {
      this.getPublicInstallmentsList();
    }),
    this.$root.$on('update-publicformativeprograms-list', (cdlId) => {
      this.getPublicFormativeProgramsList(cdlId);
    }),
    this.$root.$on('update-publicprograms-list', (formativeProgramId) => {
      this.getPublicProgramsList(formativeProgramId);
    }),
    this.$root.$on('update-uniDefaultConvention', (uniId) => {
      this.getpublicUniApiCallProspectus(uniId);
    }),
    this.$root.$on('update-externalUniversities-list', () => {
      this.getPublicExternalUniversities();
    }),
    this.getPublicCurrentYear();

  },


  mounted: function onMount() {
    this.$gtag.config({ 'cookie_flags': 'SameSite=None;Secure' });
//      this.getpublicUniApiCallProspectus();
  }

}).$mount('#app')
